
import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

export default function FbShare(props) {
  const rootStyle = (props.rootStyle ? `share-button ${props.rootStyle}` : 'share-button');
  const linkStyle = props.linkStyle || '';
  const windowSize = `width=${props.w || 600},height=${props.h || 500}`;
  const srcLink = `https://www.facebook.com/sharer/sharer.php?u=${props.pageUrl}&amp;src=sdkpreparse`;

  function openWindow(e) {
    e.preventDefault();
    if (window.open) window.open(srcLink, '_blank', windowSize);
  }

  return (
    <div className={rootStyle} data-href={props.pageUrl}>
      <a href={srcLink} className={linkStyle} onClick={openWindow}>
        <StaticImage src='../images/icon-fb.png' alt="facebook" layout="fixed" />
      </a>
    </div>
  );
};
